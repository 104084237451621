@import 'variables.scss';
@import 'nav.scss';
@import 'devtools.scss';
@import 'vuetify_add_on.scss';
.v-application .dark-theme-vti, .tele-input ul {
    padding-left: 0px;
}
.dark-theme-vti .vue-tel-input {
    background: #2a2a2a;
    border: 1px solid #4f4e4e;
    color: #fff;
    border-radius: 8px;
  }
  
  .dark-theme-vti .vue-tel-input:focus-within {
    border-color: #1976D2FF;
  }
  
  .dark-theme-vti .vti__dropdown {
    background-color: #414141;
  }
  
  .dark-theme-vti .vti__dropdown:hover,
  .dark-theme-vti .vti__dropdown.open,
  .dark-theme-vti .vti__dropdown.disabled {
    background-color: #373737;
  }
  
  .dark-theme-vti .vti__dropdown-list {
    background-color: #2a2a2a;
    border: 1px solid #4f4e4e;
    width: 350px;
  }
  
  .dark-theme-vti .vti__dropdown-item {
    color: #fff;
  }
  
  .dark-theme-vti .vti__dropdown-item.highlighted {
    background-color: #373737;
  }
  
  .dark-theme-vti .vti__selection .vti__country-code {
    color: #fff;
  }
  
  .dark-theme-vti .vti__input {
    background: #2a2a2a;
    color: #fff;
    border: none;
  }
  
  .dark-theme-vti .vti__search_box {
    background: #2a2a2a;
    border: 1px solid #4f4e4e;
    color: #fff;
  }
  
.rma-contact
input:-webkit-autofill,
.rma-contact
input:-webkit-autofill:hover,
.rma-contact
input:-webkit-autofill:focus,
.rma-contact
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: white;
}
#hero-alt .v-icon__svg {
    color: white;
}
#hero-alt {
    margin-bottom: 35px !important;
}
.validate-warranty .fields-missing {
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.25s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
}
.base-summary .pa-2 .v-card {
    background-color: transparent !important;
}
.base-body {
    color: white !important;
}
.base-body > div {
    color: white !important;
}
.hidden {
    display: none !important;
}
.validate-warranty .fields-missing {
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.25s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
}
.hidden {
    display: none !important;
}
.container-bg {
    color: white;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 6px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 0px;
}
.container-fg {
    margin: auto;
    padding: 15px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(
        155deg,
        rgba(90, 90, 90, 0.6),
        rgba(255, 255, 255, 0.096)
    );
    border-radius: 6px;
}
.hero-heading .pt-1 {
    font-size: 36px;
    letter-spacing: -0.1rem;
    left: 0;
    color: white;
    font-weight: 700;
    font-family: Roboto, sans-serif !important;
}
.hero-heading svg {
    display: none;
}
.category-container {
    padding: 25px;
    margin-top: 25px;
}
.gsp-data-table .v-data-table-header,
.gsp-data-table .v-data-footer  {
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(
        155deg,
        rgba(90, 90, 90, 0.6),
        rgba(255, 255, 255, 0.096)
    );
    border: none !important;
}
.gsp-data-table tr:not(.v-data-table-header > tr) {
    background-color: white;
    color: black;
}
#demo-data-table
    .theme--dark.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td,
#demo-data-table
    .theme--dark.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td:not(.v-data-table__mobile-row),
#demo-data-table
    .theme--dark.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > th:last-child,
#demo-data-table
    .theme--dark.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > th:not(.v-data-table__mobile-row),
#demo-data-table
    .theme--dark.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child
    > th {
    border: none !important;
    background-color: transparent;
    text-align: center !important;
}
#demo-data-table .theme--dark.v-data-table {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(0, 0, 0, 0.3);
}
#demo-data-table tr:not(.v-data-table-header > tr) {
    margin: auto;
    padding: 10px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(
        155deg,
        rgba(90, 90, 90, 0.6),
        rgba(255, 255, 255, 0)
    );
    border-radius: 10px;
    margin-bottom: 10px;
    border: 1px solid rgba(255, 255, 255, 0.3);
}
// .v-alert {
//     position: fixed;
//     z-index: 999;
//     left: 50%;
//     bottom: 50px;
//     transform: translate(-50%, -50%);
//     margin: 0 auto;
//   }
#request-data-table
    .theme--dark.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td,
#request-data-table
    .theme--dark.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td:not(.v-data-table__mobile-row),
#request-data-table
    .theme--dark.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > th:last-child,
#request-data-table
    .theme--dark.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > th:not(.v-data-table__mobile-row),
#request-data-table
    .theme--dark.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child
    > th {
    border: none !important;
    background-color: transparent !important;
}
#request-data-table .theme--dark.v-data-table {
    background-color: transparent !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-spacing: 0 0.5rem !important;
}
#request-data-table tr:not(.v-data-table-header > tr) {
    margin: auto;
    padding: 10px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(
        155deg,
        rgba(90, 90, 90, 0.6),
        rgba(255, 255, 255, 0)
    );
    margin-bottom: 10px;
    /* border: 1px solid rgba(255, 255, 255, 0.3); */
}
#request-data-table .theme--dark.v-data-table {
    background-color: transparent !important;
    color: #fff;
}
#request-data-table > .v-data-table__wrapper > table {
    border-spacing: 0 0.5rem;
    background-color: rgb(63, 63, 63);
}
.theme--dark.v-data-table .v-data-footer {
    border: none;
    margin: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(
        155deg,
        rgba(90, 90, 90, 0.6),
        rgba(255, 255, 255, 0)
    );
    /* display: none; */
}
.expansion-header {
    margin: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(
        155deg,
        rgba(90, 90, 90, 0.6),
        rgba(255, 255, 255, 0)
    );
}
.expansion-header-first {
    margin: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(
        155deg,
        rgba(90, 90, 90, 0.6),
        rgba(255, 255, 255, 0)
    );
}
.expanded-panels .v-expansion-panel-content__wrap {
    padding: 0px;
}
.v-tabs .request-tab-top .theme--dark {
    background-color: transparent !important;
}
.theme--dark.v-tabs > .v-tabs-bar {
    background-color: transparent;
}