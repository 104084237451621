

























































.base-divider .v-divider
    border-width: 3px 0 0 0 !important
.base-divider.base-divider--dense .v-divider
    border-width: 2px 0 0 0 !important
