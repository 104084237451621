































































.main-phone-div {
    border-radius: 4px;
    height: 67px;
}

.vue-tel-input {
    border: unset !important;
    box-shadow: unset !important;
    font-size: 16px;
    height: 38px;
}

.phone-border {
    border: solid 1px rgba(0, 0, 0, 0.38);
    border-radius: 4px;
    box-shadow: 0 0 0 1px transparent inset;
}
.phone-border:focus-within {
    border: solid 1px #db5120;
    border-radius: 4px;
    box-shadow: 0 0 0 1px #db5120 inset;
}
.phone-border-error {
    border: solid 1px #ff5252;
    border-radius: 4px;
    box-shadow: 0 0 0 1px #ff5252 inset;
}
